import http from '@/http-client'
import { SoinResponseModel } from '@/models/soin-model'

export class SoinService {
  private static instance: SoinService

  public static getInstance (): SoinService {
    if (!SoinService.instance) {
      SoinService.instance = new SoinService()
    }
    return SoinService.instance
  }

  // Soins
  public async getAllSoins () {
    const response = await http.get('/soin')
    return (response.data) as SoinResponseModel[]
  }
}

export const DefinitifStatus = 1

export const allAgendaStatus = [
  { text: 'Définitif', value: DefinitifStatus },
  { text: 'Annulé AFD', value: 2 },
  { text: 'Annulé patient', value: 3 },
  { text: 'Déplacé AFD', value: 4 },
  { text: 'Déplacé patient', value: 5 },
  { text: 'Inexcusé', value: 6 }
]

export const annulationAgendaStatus = [
  { text: 'Annulé AFD', value: 2 },
  { text: 'Annulé patient', value: 3 }
]

export const deplacementAgendaStatus = [
  { text: 'Déplacé AFD', value: 4 },
  { text: 'Déplacé patient', value: 5 }
]

export const defaultAgendaStatus = [
  { text: 'Définitif', value: 1 },
  { text: 'Inexcusé', value: 6 }
]

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver } from 'vee-validate'
import { AgendaService } from '@/services/agenda-service'
import { annulationAgendaStatus } from '@/views/Agenda/constants'
import { AgendaEvent, CancelAppointmentModel } from '@/models/agenda-model'
import Commons from '../../Helpers/commons'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class CancelAgendaDialog extends Vue {
  private agendaService = AgendaService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: null })
  public event!: AgendaEvent

  public possibleNewStatuses = annulationAgendaStatus

  public state: {newStatus?: number; reason?: string} = {}
  public isSaving = false
  private errorMessages: string[] = []

  @Watch('visible') onVisibleChange (v) {
    if (v) {
      this.resetDialog()
    }
  }

  public mounted () {
    this.resetDialog()
  }

  private resetDialog () {
    this.state = {}
    this.errorMessages = []
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) {
      observer.reset()
    }
  }

  get isNewStatusSet () {
    return !!this.state.newStatus
  }

  get show () {
    return this.visible
  }

  get getDetail () {
    const patientPart = this.event.patient ? `du patient <strong>${this.event.patient}</strong> avec` : 'de'
    const text = `Voulez-vous annuler le rendez-vous <span class="text-lowercase">${this.event.name}</span> du <strong>${Commons.TransformDateFormat(this.event.start.toISOString())} à ${this.event.start.getHours()}:${Commons.padWithZeros(this.event.start.getMinutes(), 2)}</strong> ${patientPart} <strong>${this.event.userName}</strong> ?`
    return text
  }

  public close () {
    this.$emit('close')
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      const data: CancelAppointmentModel = {
        id: this.event.id!,
        statusId: this.state.newStatus!,
        reason: this.state.reason
      }
      const response = await this.agendaService.cancelAppointment(data)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (response) {
        this.close()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}

import Vue from 'vue'
import { Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver } from 'vee-validate'
import { AgendaService } from '@/services/agenda-service'
import { deplacementAgendaStatus, DefinitifStatus } from '@/views/Agenda/constants'
import { AgendaEvent, MoveAppointmentTimeData, MoveAppointmentModel, AgendaDialogFormModel } from '@/models/agenda-model'
import { RoomModel, RoomPlanningPeriodModel } from '@/models/rooms-model'
import AgendaDialogForm from '../AgendaDialog/AgendaDialogForm/AgendaDialogForm.vue'
import { AppUser, AbsenceModel } from '@/models/app-user-dto'
import { SoinResponseModel } from '@/models/soin-model'
import AgendaHelpers from '../../Helpers/agenda-helpers'
import Commons from '../../Helpers/commons'

@Component({
  components: {
    AgendaDialogForm,
    Alert,
    ValidationObserver
  }
})
export default class MoveAgendaDialog extends Vue {
  private agendaService = AgendaService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: null })
  public oldEvent!: AgendaEvent

  @Prop({ default: null })
  public timeData!: MoveAppointmentTimeData

  @Prop({ default: [] })
  public allRooms!: RoomModel[]

  @Prop({ default: [] })
  public allInfirmieres!: AppUser[]

  @Prop({ default: [] })
  public allSoins!: SoinResponseModel[]

  @Prop({ default: [] })
  public allAbsences!: AbsenceModel[]

  @Prop({ default: [] })
  public relevantPlannings!: RoomPlanningPeriodModel[]

  @Prop({ default: 0 })
  public agendaType!: number

  @PropSync('currentSite')
  public syncCurrentSite!: number

  @PropSync('currentUser')
  public syncCurrentUser!: string

  public possibleNewStatuses = deplacementAgendaStatus

  private formState: AgendaDialogFormModel = AgendaHelpers.DefaultAgendaFormState(this.oldEvent)

  public moveState: {newStatus?: number; reason?: string} = {}
  public isSaving = false
  private errorMessages: string[] = []
  public loadingAppointment = false

  @Watch('visible') onVisibleChange (v) {
    if (v) {
      this.resetDialog()
    }
  }

  public mounted () {
    this.resetDialog()
  }

  private resetDialog () {
    this.moveState = {}
    this.formState = AgendaHelpers.DefaultAgendaFormState(this.oldEvent)
    if (this.formState.event) {
      const duration = this.formState.event.end.getTime() - this.formState.event.start.getTime()
      const durationMinutes = duration / 1000 / 60
      this.formState.event.start = new Date(this.timeData.date)
      const extracted = AgendaHelpers.ExtractHours(this.timeData.startTime, true)
      this.formState.event.start.setHours(extracted.hours)
      this.formState.event.start.setMinutes(extracted.minutes)
      this.formState.event.category = this.timeData.category!

      const newDate = new Date(this.formState.event.start.getFullYear(), this.formState.event.start.getMonth(), this.formState.event.start.getDate(), extracted.hours, extracted.minutes + durationMinutes)
      this.formState.event.end = newDate

      const theDate = Commons.FormatDateForInputField(this.formState.event.start)
      const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, theDate)
      if (planning) {
        const roomPlanning = AgendaHelpers.FindRoomPlanning(planning, this.allRooms.filter(r => r.siteId === this.syncCurrentSite), this.formState.event.category)
        if (roomPlanning) {
          const nurse = this.allInfirmieres.find(i => i.id.toLowerCase() === roomPlanning![`day${new Date(theDate).getDay()}AssignedNurseId`]?.toLowerCase())
          if (nurse) {
            this.formState.event.nurseId = nurse.id
          }
        }
      }
    }
    this.errorMessages = []
    this.isSaving = false
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) {
      observer.reset()
    }
  }

  get isNewStatusSet () {
    return !!this.moveState.newStatus
  }

  get show () {
    return this.visible
  }

  get getDetail () {
    const patientPart = this.oldEvent.patient ? `du patient '${this.oldEvent.patient}'` : ''
    const text = `Déplacer le rendez-vous ${this.oldEvent.name} ${patientPart} avec '${this.oldEvent.userName}' ?`
    return text
  }

  public onSiteChange (e) {
    this.$emit('agendaDialogSiteChange', e)
  }

  public onAgendaDialogFormError (e) {
    this.errorMessages = e
  }

  public onNurseChange (e) {
    this.$emit('agendaDialogUserChange', e)
  }

  public onLoadingAppointmentChange (e) {
    this.loadingAppointment = e
  }

  public close () {
    this.$emit('close')
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      const appointment = AgendaHelpers.GenerateAppointmentReqModel(this.formState, this.oldEvent)
      appointment.id = 0
      const data: MoveAppointmentModel = {
        id: this.oldEvent.id!,
        statusId: this.isOtherAppointmentType ? DefinitifStatus : this.moveState.newStatus!,
        reason: !this.isOtherAppointmentType ? this.moveState.reason : undefined,
        newAppointment: appointment
      }

      const response = await this.agendaService.moveAppointment(data)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (response) {
        this.close()
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.agendaDialogForm as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public get isOtherAppointmentType () {
    return AgendaHelpers.IsOtherAppointmentTypeValue(this.oldEvent.appointmentTypeId)
  }

  public hideAlert () {
    this.errorMessages = []
  }
}

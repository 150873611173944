import Vue from 'vue'
import { Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { AgendaDialogFormModel } from '@/models/agenda-model'
import { rdvType, prestationCategories } from '@/views/Patients/Dossier/Constants'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { RoomModel, RoomPlanningPeriodModel } from '@/models/rooms-model'
import { PrestationAgendaResponseModel } from '@/models/prescriptions-model'
import Alert from '@/components/shared/Alert.vue'
import { TextValue } from '@/components/shared/Helpers/common-models'
import { AbsenceModel, AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import AgendaHelpers from '@/components/shared/Helpers/agenda-helpers'
import { ErrorService } from '@/services/error.service'
import { siteItems, soinsTypes, calendarColors, calendarColorsInexcuse } from '@/views/Administration/Constants'
import { ValidationObserver } from 'vee-validate'
import { SoinResponseModel } from '@/models/soin-model'
import { AgendaService } from '@/services/agenda-service'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { PatientService } from '@/services/patient-service'

@Component({
  components: {
    Alert
  }
})
export default class AgendaDialogForm extends Vue {
  private patientsService = PatientService.getInstance()

  public colors = calendarColors
  public colorsInexcuse = calendarColorsInexcuse

  @PropSync('currentSite')
  public syncCurrentSite!: number

  @PropSync('currentUser')
  public syncCurrentUser!: string

  @Prop({ default: {} })
  public value!: AgendaDialogFormModel

  @Prop({ default: [] })
  public allRooms!: RoomModel[]

  @Prop({ default: [] })
  public allInfirmieres!: AppUser[]

  @Prop({ default: [] })
  public allSoins!: SoinResponseModel[]

  @Prop({ default: [] })
  public allAbsences!: AbsenceModel[]

  @Prop({ default: [] })
  public relevantPlannings!: RoomPlanningPeriodModel[]

  @Prop({ default: 0 })
  public agendaType!: number

  @Prop({ default: false })
  public visible!: boolean

  @Prop({ default: false })
  public isMoveOperation!: boolean

  // public members
  public filteredSites = siteItems

  public roomsForSelectedSite: RoomModel[] = []

  public infirmieresForSelectedSoins: AppUser[] = []

  public soinTypesForSelectedRooms: TextValue[] = []

  public currentPatientAllPrestations: PrestationAgendaResponseModel[] = []
  public currentPatientPrestationsFiltered: PrestationAgendaResponseModel[] = []

  public allSites = siteItems
  public allSoinTypes = soinsTypes
  public types = rdvType

  public readyForPrestations = true

  private showPrestationInfo = false

  private isInitialized = false

  // services
  private prescriptionService = PatientPrescriptionService.getInstance()
  private agendaService = AgendaService.getInstance()

  public dbDossiers: dossierPatientModel[] = []
  public searchPatient = ''
  public selectedPatient?: dossierPatientModel
  public readyPatients = true
  private hasFetchedAppointmentDetails = false

  private userSetCustomTimeRange = false

  public updateIntervenantDropdown = 0

  public mounted () {
    this.onVisibilityToggle()
  }

  @Watch('currentUser')
  public onCurrentUserChange (e: any) {
    this.$emit('onNurseChange', e)
  }

  @Watch('syncCurrentSite')
  public onSiteIdChange (e: any) {
    this.$emit('onCurrentSiteChange', e)
  }

  @Watch('soinTypesForSelectedRooms')
  public onSoinTypesForSelectedRoomsChange (e: TextValue[]) {
    if (!!e && e.length === 1) {
      this.onTypeSoinChanged(e[0].value)
      this.value.soinTypeSelected = e[0].value
    }
  }

  @Watch('visible')
  public onVisibilityToggle () {
    if (this.visible) {
      this.readyForPrestations = true
      this.showPrestationInfo = false
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      if (observer) {
        observer.reset()
      }
      this.isInitialized = false
      this.soinTypesForSelectedRooms = []
      // seems like a timing issue or something with the order in which the @Watch get called is not quite right and
      // what sometimes happens is that the duration is reset to 0 because the AgendaHelpers.DefaultAgendaFormState
      // initializes it to 0 and this is apparently sometimes the value that's considered. If we delay our internal
      // initialization here to the next tick, the AgendaHelpers.DefaultAgendaFormState call will have executed and
      // will not be able to interfere with the computations we perform here.
      this.$nextTick(() => {
        this.initialize()
      })
    }
  }

  private initialize () {
    if (this.value.event) {
      this.value.isFixed = this.value.event.isFixed
      this.userSetCustomTimeRange = this.value.event.start !== this.value.event.end
      this.value.theDate = Commons.FormatDateForInputField(this.value.event.start)
      this.value.timeStart = Commons.FormatTimeForInputField(this.value.event.start)
      this.value.duration = (this.value.event.end.getHours() * 60 + this.value.event.end.getMinutes()) - (this.value.event.start.getHours() * 60 + this.value.event.start.getMinutes())
      this.value.timeEnd = Commons.FormatTimeForInputField(this.value.event.end)
      if (AgendaHelpers.IsAgendaLieu(this.agendaType)) {
        this.filteredSites = siteItems
        this.roomsForSelectedSite = AgendaHelpers.FilterRoomsBySite(this.syncCurrentSite, this.allRooms)
        this.onRoomChange(this.value.event.category)
      } else if (AgendaHelpers.IsAgendaUser(this.agendaType)) {
        this.value.intervenant = this.syncCurrentUser
        const theInfirmiere = Commons.FindNurse(this.allInfirmieres, this.syncCurrentUser)
        if (theInfirmiere) {
          if (theInfirmiere.soinsTypeIds.length > 0) {
            this.value.soinTypeSelected = theInfirmiere.soinsTypeIds[0]
          }
          this.roomsForSelectedSite = AgendaHelpers.FilterRoomsByInfirmiere(theInfirmiere, this.allRooms)
          this.filteredSites = AgendaHelpers.FilterSitesByRooms(this.roomsForSelectedSite, this.allSites)
          this.syncCurrentSite = this.filteredSites[0].siteId
          this.onSiteChange(this.filteredSites[0].siteId)
          this.roomsForSelectedSite = AgendaHelpers.FilterRoomsBySite(this.filteredSites[0].siteId, this.allRooms)
        }
      }

      if (this.value.event.id) {
        // edit mode
        this.$emit('loadingAppointmentChange', true)
        this.agendaService.getAppointmentDetail(this.value.event.id).then(async (detail) => {
          this.value.type = detail.appointmentTypeId
          this.value.otherLocation = detail.otherLocation
          this.value.description = detail.description
          this.value.importantInfo = detail.importantInfo
          let room
          if (this.isMoveOperation) {
            room = this.roomsForSelectedSite.find(r => r.name === this.value.event.category)
            this.value.roomId = room?.id
          } else {
            this.value.roomId = detail.roomId
            room = this.allRooms.find(r => r.id === detail.roomId)
            this.value.event.category = room?.name!
          }
          this.value.intervenant = this.value.event.nurseId
          if (this.isConsultation) {
            const prestations = await this.prescriptionService.GetPatientPrestations(detail.dossierId!, this.value.event.id)
            this.mapPrestations(prestations)
            this.value.prestation = detail.prestationId
            this.value.soinTypeSelected = detail.consultationTypeId
            this.value.event.color = AgendaHelpers.GetEventColor(this.colors, this.value.soinTypeSelected, this.value.type, this.value.isFixed)
            this.currentPatientPrestationsFiltered = AgendaHelpers.FilterPrestations(this.currentPatientAllPrestations, this.value.soinTypeSelected)

            if (detail.dossierId) {
              await this.patientsService.getDossierPatientById(detail.dossierId).then((ds: any) => {
                this.dbDossiers = [ds]
              }).catch(async (errs) => {
                const res = await ErrorService.handleError(errs)
                this.$emit('error', res.errors)
              })
            }

            this.value.patient = this.dbDossiers.find(d => d.guid.toLowerCase() === detail.dossierId.toLowerCase())
            if (AgendaHelpers.IsAgendaUser(this.agendaType)) {
              // set site and room
              const newSiteValue = this.allRooms.find(r => r.id === detail.roomId)?.siteId!
              this.syncCurrentSite = newSiteValue
              this.onSiteChange(newSiteValue)
              this.roomsForSelectedSite = AgendaHelpers.FilterRoomsBySite(newSiteValue, this.allRooms)
              this.soinTypesForSelectedRooms = AgendaHelpers.FilterSoinTypesByRoomsAndNurse(this.roomsForSelectedSite, [detail.roomId], this.allSoinTypes, this.allInfirmieres.find(i => i.id.toLowerCase() === this.value.intervenant)!)
            }
          }
          this.filterInfirmieres(this.value.soinTypeSelected, detail.nurseId)
        }).catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.$emit('error', res.errors)
        }).finally(() => {
          this.hasFetchedAppointmentDetails = true
          this.checkPlanningIssue(this.value.event.category)
          this.$emit('loadingAppointmentChange', false)
        })
      } else {
        this.checkPlanningIssue(this.value.event.category)
      }

      if (this.value.event.category) {
        this.value.roomId = this.roomId(this.value.event.category)
      }
    } else {
      this.$emit('close')
    }
    this.isInitialized = true
  }

  private checkPlanningIssue (roomName: string) {
    if (!!roomName && this.soinTypesForSelectedRooms.length <= 0 && this.infirmieresForSelectedSoins.length <= 0) {
      this.$emit('error', ['Il y a peut-être un problème au niveau du planning. Vérifiez le planning concerné!'])
    }
  }

  public update () {
    this.$emit('input', this.value)
  }

  private onTimeVariableChange () {
    if (this.isInitialized) {
      const extracted = AgendaHelpers.ExtractHours(this.value.timeStart, true)
      const newDate = new Date(this.value.event.start.getFullYear(), this.value.event.start.getMonth(), this.value.event.start.getDate(), extracted.hours, extracted.minutes + parseInt(this.value.duration.toString()))
      this.value.timeEnd = Commons.FormatTimeForInputField(newDate)
      this.value.event.end = newDate
      this.filterInfirmieres(this.value.soinTypeSelected)
    }
  }

  private onTimeEndVariableChange () {
    if (this.isInitialized) {
      const extStart = AgendaHelpers.ExtractHours(this.value.timeStart, true)
      const extEnd = AgendaHelpers.ExtractHours(this.value.timeEnd, false)
      const startDate = new Date(this.value.event.start.getFullYear(), this.value.event.start.getMonth(), this.value.event.start.getDate(), extStart.hours, extStart.minutes)
      const endDate = new Date(this.value.event.start.getFullYear(), this.value.event.start.getMonth(), this.value.event.start.getDate(), extEnd.hours, extEnd.minutes)
      this.value.duration = Math.round((endDate.getTime() - startDate.getTime()) / 60000)
      this.filterInfirmieres(this.value.soinTypeSelected)
    }
  }

  public onTimeStartChanged (e) {
    if (e) {
      const debutDateObject = this.$refs.DébutRef as Vue
      this.value.timeStart = Commons.roundTimeFieldToNearest(e, debutDateObject, 'DebutId', 5)
    }
  }

  public onTimeEndChanged (e) {
    if (e) {
      const finDateObject = this.$refs.FinRef as Vue
      this.value.timeEnd = Commons.roundTimeFieldToNearest(e, finDateObject, 'FinId', 5)
    }
  }

  @Watch('value.timeStart')
  public startChanges () {
    this.onTimeVariableChange()
  }

  @Watch('value.timeEnd')
  public endChanges () {
    this.onTimeEndVariableChange()
  }

  @Watch('value.duration')
  public durationChanges () {
    this.onTimeVariableChange()
  }

  get isConsultation () {
    return AgendaHelpers.IsConsultationAppointmentType(this.value)
  }

  get isOther () {
    return AgendaHelpers.IsOtherAppointmentType(this.value)
  }

  public onPatientChange (e) {
    this.selectedPatient = this.dbDossiers.find(g => g.guid === e.guid)
    if (this.readyForPrestations) {
      this.readyForPrestations = false
      this.prescriptionService.GetPatientPrestations(e.guid, this.value.event.id).then(async (prestations) => {
        this.mapPrestations(prestations)
        // for diabete and dietetique, check if we have prestations that are already at the limit
        await this.updateShowPrestationInfo(e.guid)
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.$emit('error', res.errors)
      }).finally(() => {
        this.readyForPrestations = true
      })
    }
  }

  private async updateShowPrestationInfo (patientId: string) {
    const soinsWithLimit = [
      1, // diabétique
      2, // diététique
      4 // pédiatrie
    ]
    if (soinsWithLimit.some(s => s === this.value.soinTypeSelected)) {
      this.showPrestationInfo = await this.prescriptionService.HasPrestationsPatientAtLimit(patientId, this.value.event.id)
    } else {
      this.showPrestationInfo = false
    }
  }

  get displayPrestationInfo () {
    return this.showPrestationInfo
  }

  public onNurseChange (e) {
    this.$emit('onNurseChange', e)
  }

  public onSiteChange (e) {
    this.syncCurrentSite = e
    this.roomsForSelectedSite = AgendaHelpers.FilterRoomsBySite(e, this.allRooms)
    this.value.roomId = undefined
    this.value.event.category = ''
    this.onRoomChange(this.value.roomId)
    this.$emit('onCurrentSiteChange', e)
  }

  public roomId (roomName) {
    return this.roomsForSelectedSite.find((r) => r.name === roomName)?.id!
  }

  public onRoomChange (e) {
    if (this.value.event) {
      const theRoom = this.roomsForSelectedSite.find((r) => r.name === e)
      const selectedRoomId = theRoom?.id!
      const theDate = new Date(this.value.theDate!)
      if (theDate && theRoom) {
        const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, this.value.theDate!)
        if (planning) {
          const roomPlanning = AgendaHelpers.FindRoomPlanningByRoomId(planning, selectedRoomId)
          if (roomPlanning) {
            const nurse = this.allInfirmieres.find(i => i.id.toLowerCase() === roomPlanning![`day${theDate.getDay()}AssignedNurseId`]?.toLowerCase())
            if (nurse) {
              this.value.intervenant = nurse.id
              this.soinTypesForSelectedRooms = AgendaHelpers.FilterSoinTypesByRoomsAndNurse(this.roomsForSelectedSite, [selectedRoomId], this.allSoinTypes, nurse)
            } else {
              this.value.intervenant = ''
              this.soinTypesForSelectedRooms = []
            }
          } else {
            this.value.intervenant = ''
            this.soinTypesForSelectedRooms = []
          }
        } else {
          this.value.intervenant = ''
          this.soinTypesForSelectedRooms = []
        }
        this.checkPlanningIssue(e)
      }
      if (AgendaHelpers.IsAgendaLieu(this.agendaType)) {
        this.value.soinTypeSelected = undefined
      }
      this.onTypeSoinChanged(this.value.soinTypeSelected)
      this.value.roomId = selectedRoomId
    }
  }

  public async onTypeSoinChanged (soinId) {
    if (this.value.event) {
      const soinDifferent = soinId !== this.value.soinTypeSelected
      this.value.soinTypeSelected = soinId
      this.value.event.color = AgendaHelpers.GetEventColor(this.colors, this.value.soinTypeSelected!, this.value.type, this.value.isFixed)
      this.filterInfirmieres(soinId, this.value.intervenant) // TODO check if we want `this.value.intervenant` here
      if (!this.infirmieresForSelectedSoins.find(i => i.id.toLowerCase() === this.value.intervenant.toLowerCase())) {
        this.value.intervenant = ''
      }
      this.currentPatientPrestationsFiltered = AgendaHelpers.FilterPrestations(this.currentPatientAllPrestations, soinId)
      if (!this.currentPatientPrestationsFiltered.find(p => p.id === this.value.prestation)) {
        this.value.prestation = ''
      }
      if (AgendaHelpers.IsConsultationAppointmentType(this.value) && soinDifferent) {
        await this.updateShowPrestationInfo(this.value.patient?.guid ?? '')
        const defaultDuration = this.allSoins.find((s) => s.typeId === soinId)?.duration
        // only set default duration in creation mode or after the appointment details have been fetched
        if (defaultDuration && !this.userSetCustomTimeRange && (!this.value.event.id || this.hasFetchedAppointmentDetails)) {
          this.value.duration = defaultDuration
        }
      }
    }
  }

  public onTypeAppointmentChanged (type) {
    ++this.updateIntervenantDropdown
    this.value.type = type
    this.value.event.color = AgendaHelpers.GetEventColor(this.colors, this.value.soinTypeSelected!, this.value.type, this.value.isFixed)
    this.filterInfirmieres(this.value.soinTypeSelected)
  }

  private mapPrestations (prestations: PrestationAgendaResponseModel[]) {
    prestations.forEach((prestation: PrestationAgendaResponseModel) => {
      prestation.display = `${prestationCategories.find((p) => p.value === prestation.prestationTypeId)?.text} - ${Commons.TransformDateFormat(prestation.dateStart)} - ${prestation.prescriptionDoctorName}`
    })
    this.currentPatientAllPrestations = prestations
    this.currentPatientPrestationsFiltered = AgendaHelpers.FilterPrestations(this.currentPatientAllPrestations, this.value.soinTypeSelected!)
    if (!this.currentPatientPrestationsFiltered.find(p => p.id === this.value.prestation)) {
      this.value.prestation = ''
    }
  }

  @Watch('searchPatient')
  public searchPatientChanged (v) {
    if (v && v.length > 2) {
      this.refreshPatients()
    } else {
      this.dbDossiers = []
    }
  }

  public get patients () {
    if (this.selectedPatient?.fullName) {
      return [...this.dbDossiers, this.selectedPatient].sort((a, b) => (a.fullName.toLocaleLowerCase()).localeCompare(b.fullName.toLocaleLowerCase()))
    } else if (this.value.patient) {
      return [...this.dbDossiers, this.value.patient].sort((a, b) => (a.fullName.toLocaleLowerCase()).localeCompare(b.fullName.toLocaleLowerCase()))
    }
    return this.dbDossiers
  }

  public refreshPatients () {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    this.patientsService.getActivePatients(this.searchPatient).then((ds: any) => {
      this.dbDossiers = ds.value
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.$emit('error', res.errors)
    }).finally(() => {
      this.readyPatients = true
    })
  }

  public displayPatient (item: dossierPatientModel) {
    return Commons.autocompleteDisplayPatient(item, this.searchPatient)
  }

  private filterInfirmieres (soinId, nurseId?: string) {
    const start = AgendaHelpers.ExtractHours(this.value.timeStart, true)
    const end = AgendaHelpers.ExtractHours(this.value.timeEnd, false)

    const startDate = new Date(this.value.event.start.getFullYear(), this.value.event.start.getMonth(), this.value.event.start.getDate(), start.hours, start.minutes)
    const endDate = new Date(this.value.event.start.getFullYear(), this.value.event.start.getMonth(), this.value.event.start.getDate(), end.hours, end.minutes)
    this.infirmieresForSelectedSoins = AgendaHelpers.FilterNursesBySoinAndAbsence(this.allInfirmieres, soinId, this.allAbsences, startDate, endDate, this.value.type, nurseId)
    this.checkPlanningIssue(this.value.event.category)
  }
}

import Vue from 'vue'
import { Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { AgendaDialogFormModel, AgendaEvent } from '@/models/agenda-model'
import { AbsenceModel, AppUser } from '@/models/app-user-dto'
import { RoomModel, RoomPlanningPeriodModel } from '@/models/rooms-model'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver } from 'vee-validate'
import { SoinResponseModel } from '@/models/soin-model'
import { AgendaService } from '@/services/agenda-service'
import AgendaDialogForm from './AgendaDialogForm/AgendaDialogForm.vue'
import AgendaHelpers from '../../Helpers/agenda-helpers'
import { defaultAgendaStatus } from '@/views/Agenda/constants'
import Commons from '../../Helpers/commons'

@Component({
  components: {
    AgendaDialogForm,
    Alert,
    ValidationObserver
  }
})
export default class AgendaDialog extends Vue {
  private agendaService = AgendaService.getInstance()

  @Prop({ default: false })
  public visible!: boolean

  @Prop({ default: null })
  public event!: AgendaEvent

  @Prop({ default: [] })
  public allRooms!: RoomModel[]

  @Prop({ default: [] })
  public allInfirmieres!: AppUser[]

  @Prop({ default: [] })
  public allSoins!: SoinResponseModel[]

  @Prop({ default: [] })
  public allAbsences!: AbsenceModel[]

  @Prop({ default: [] })
  public relevantPlannings!: RoomPlanningPeriodModel[]

  @Prop({ default: 0 })
  public agendaType!: number

  @PropSync('currentSite')
  public syncCurrentSite!: number

  @PropSync('currentUser')
  public syncCurrentUser!: string

  private formState: AgendaDialogFormModel = AgendaHelpers.DefaultAgendaFormState(this.event)

  public possibleNewStatuses = defaultAgendaStatus

  public isSaving = false

  public errorMessages: string[] = []
  public loadingAppointment = false

  public newStatus = 1

  @Watch('visible')
  public onVisibilityToggle () {
    if (this.visible) {
      this.formState = AgendaHelpers.DefaultAgendaFormState(this.event)
      this.newStatus = this.event.statusId === 6 ? 6 : 1
      this.errorMessages = []
      this.isSaving = false
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      if (observer) {
        observer.reset()
      }
    }
  }

  public onSiteChange (e) {
    this.$emit('agendaDialogSiteChange', e)
  }

  public onAgendaDialogFormError (e) {
    this.errorMessages = e
  }

  public onNurseChange (e) {
    this.$emit('agendaDialogUserChange', e)
  }

  public onLoadingAppointmentChange (e) {
    this.loadingAppointment = e
  }

  get dialogTitle () {
    return `${this.event !== null && this.event.id! > 0 ? "Éditer" : "Ajouter"} un rendez-vous`
  }

  get show () {
    return this.visible
  }

  public close (warnings: string[]) {
    this.$emit('close', { event: this.event, warnings: warnings })
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      const appointment = AgendaHelpers.GenerateAppointmentReqModel(this.formState, this.event)
      if (this.event.id && !this.isOtherAppointmentType) {
        appointment.statusId = this.newStatus
      }
      this.agendaService.addUpdateAppointment(appointment)
        .then(response => {
          if (response.id > 0) {
            this.close(response.warnings)
          }
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.agendaDialogForm as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public get isOtherAppointmentType () {
    return AgendaHelpers.IsOtherAppointmentTypeValue(this.event.appointmentTypeId)
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
